<template>
    <div class="help_center_container">
        <Header></Header>
        <div class="container">
            <h2 class="help_center_title">帮助中心</h2>
            <ul class="question_list">
                <li class="question_li">
                    <h3 class="question_title">疑问1：是否报名成功了？为什么官网查不到报名信息？</h3>
                    <p class="question_answer clearfix">
                        <span class="f_left">答：</span>
                        <span class="f_left">在“马拉松报名”报名成功以支付成功为准，在“我的”里可以查看报名信息，一经报名，不予退款。<br>报名期间以【马拉松报名】显示的报名状态为准，在赛事官网无法查询到属于正常现象，名单将在 报名结束后统一提交给组委会，5个工作日内，组委会会录入官网，并且发送短信，感谢您的信任与支持。</span>
                    </p>
                </li>
                <li class="question_li">
                    <h3 class="question_title">疑问2：我第一次参赛，没有证书，无法上传怎么办？</h3>
                    <p class="question_answer clearfix">
                        答：第一次参赛，没有证书，可上传体检报告。
                    </p>
                </li>
                <li class="question_li">
                    <h3 class="question_title">疑问3：如何报名赛事？</h3>
                    <p class="question_answer clearfix">
                        答：可下载“马拉松报名”APP或是微信搜一搜小程序：马拉松官方报名，可搜索地区/赛事名称，查看并报名赛事。
                    </p>
                </li>
                <li class="question_li">
                    <h3 class="question_title">疑问4：赛事举办日期？</h3>
                    <p class="question_answer clearfix">
                        答：“马拉松报名”公众号菜单栏-赛事信息-2019赛事日历中可查看。
                    </p>
                </li>
                <li class="question_li">
                    <h3 class="question_title">疑问5：报名之后可以进行退款吗？</h3>
                    <p class="question_answer clearfix">
                        答：一经报名，不予退款。因为组委会原因退款，1-3个工作日原路返回。
                    </p>
                </li>
                <li class="question_li clearfix">
                    <h3 class="question_title f_left">更多问题请进入建议反馈中提交，我们致力于给您提供越来越好的赛事服务！</h3>
                    <p class="feedback f_right">
                        <router-link to="/advice">
                            <i class="feedback_icon"></i>
                            <span>建议反馈</span>
                        </router-link>
                    </p>
                </li>
            </ul>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    name: 'helpCenter',
}
</script>
<style scoped>
    @import './style/helpCenter.css';
</style>